import React from "react";

const Features = () => {
  return (
    <section className="service-one" id="features">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            와글TMS는 <span>다른 ... 과도</span> <br /> 이렇게 다릅니다.
          </h2>
        </div>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-laptop"></i>
                <h3>
                  <a href="#none">
                    현장성 <br /> 정말 편합니다.
                  </a>
                </h3>
                <p>
                  Lorem ipsum is are <br /> many variations of <br /> pass of
                  majority.
                </p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-presentation"></i>
                <h3>
                  <a href="#none">
                    네트워크 <br /> 연결은 힘입니다.
                  </a>
                </h3>
                <p>
                  Lorem ipsum is are <br /> many variations of <br /> pass of
                  majority.
                </p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-target"></i>
                <h3>
                  <a href="#none">
                    정확성 <br /> 자동으로, 스스로 알아서 ...
                  </a>
                </h3>
                <p>
                  Lorem ipsum is are <br /> many variations of <br /> pass of
                  majority.
                </p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 col-sm-12 wow fadeInDown"
            data-wow-duration="1500ms"
          >
            <div className="service-one__single text-center">
              <div className="service-one__inner">
                <i className="service-one__icon dimon-icon-visualization"></i>
                <h3>
                  <a href="#none">
                    데이터의 힘 <br /> 딥러닝, AI 자동예측
                  </a>
                </h3>
                <p>
                  Lorem ipsum is are <br /> many variations of <br /> pass of
                  majority.
                </p>
                <a href="#none" className="service-one__link">
                  <i className="dimon-icon-right-arrow"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Features;
